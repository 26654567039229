import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Redirect, Link } from "react-router-dom";
import { Layout, Menu } from 'antd';
import "antd/dist/antd.css";

import './App.scss';
import logo from './logo.svg';
import Urls from './Pages/Urls';
import Pics from './Pages/Pics';
import Schedule from './Pages/Schedule';

const { Header, Content } = Layout;

class App extends Component {
  render() {
    return (
        <Router>
          <Layout className='app'>
            <Header>
              <Link to="/"><div className="logo">
                <img src={logo} alt='' />
              </div></Link>
              <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={[document.location.pathname]}
                style={{ lineHeight: '64px' }}
              >
                <Menu.Item key="/urls">
                  <Link to="/urls">Генератор ссылок</Link>
                </Menu.Item>
                {/*<Menu.Item key="/schedule">*/}
                {/*  <Link to="/schedule">Расписание</Link>*/}
                {/*</Menu.Item>*/}
              </Menu>
            </Header>
            <Content className='content'>
              <Route exact path="/" render={() => <Redirect to="/urls" />} />
              <Route path="/urls" component={Urls} />
              <Route path="/pics" component={Pics} />
              <Route path="/schedule" component = {Schedule} />
            </Content>
          </Layout>
        </Router>
    );
  }
}

export default App;
