import React from 'react';
import './schedule.scss';
import { block } from 'bem-cn';
import _ from 'lodash';
import logo from './logo.svg';
import { Select, Row, Col } from 'antd';
import axios from 'axios';
import { Helmet } from "react-helmet";

class App extends React.Component {
    state = {
        partnerId: null,
        cityFrom: null,
        cityTo: null,
        partners: null,
        cities: null,
        schedule: {
            from: null,
            to: null
        },
        country: 'ru'
    }
    componentDidMount() {
        this.loadCities();
    }
    loadCities() {
        axios.get('/api/schedule/cities')
            .then(({data}) => {
                this.setState({cities: data});
            })
    }
    selectPartner(partnerId) {
        this.setState({partnerId: partnerId, lineFrom: null, lineTo: null}, () => {
            this.loadSchedule();
        });
    }
    selectCity(cityFromId, cityToId) {
        const cityFrom = cityFromId ? this.state.cities.filter(c => c.id === parseInt(cityFromId))[0] : undefined;
        const cityTo = cityToId ? this.state.cities.filter(c => c.id === parseInt(cityToId))[0] : undefined;
        this.setState({
            cityFrom: cityFrom || this.state.cityFrom, 
            cityTo: cityTo || this.state.cityTo
        }, () => {
            if (this.state.cityFrom && this.state.cityTo) {
                this.loadPartners();
            }
            this.loadSchedule()
        })
    }
    loadPartners() {
        axios.get('/api/schedule/partners', {
            params: {
                fromId: this.state.cityFrom.id,
                toId: this.state.cityTo.id
            }
        })
        .then(({data}) => {
            // console.log(data);
            this.setState({partners: data})
        })
    }
    loadSchedule() {
        const {partnerId, cityFrom, cityTo} = this.state;
        if (partnerId && cityFrom && cityTo) {
            axios.get('/api/schedule', {
                params: {
                    partnerId: partnerId,
                    from: cityFrom.id,
                    to: cityTo.id
                }
            })
            .then(({data}) => {
                this.setState({
                    schedule: {
                        from: data[cityFrom.id],
                        to: data[cityTo.id]
                    }
                }, () => console.log(this.state));
            })
        }
    }
    /**
     * 
     * @param {String} inputValue 
     * @param {*} option 
     */
    filterOption(inputValue, option) {
        return option.props.text.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) > -1;
    }
    render() {
        const b = block('schedule');
        const fromName = this.state.cityFrom ? this.state.cityFrom.city_name : '';
        const toName = this.state.cityTo ? this.state.cityTo.city_name : '';
        const weekDays = [
            'Понедельник', 'Вторник', 'Среда', 'Четверг',
            'Пятница', 'Суббота', 'Воскресенье'
        ]
        const maxLength = _.max([
            this.state.schedule.from ? _.max(_.values(this.state.schedule.from).map(s => s.length)) : 0,
            this.state.schedule.to ? _.max(_.values(this.state.schedule.to).map(s => s.length)) : 0,
        ])
        return (
            <div className={b()}>
                <Helmet>
                    <title>Атлас — Генератор ссылок</title>
                </Helmet>
                <Row gutter={16}>
                    <Col span={8}>
                        <Select
                            value={this.state.cityFrom ? this.state.cityFrom.id : null}
                            loading={!this.state.cities}
                            placeholder="Откуда"
                            disabled={!this.state.cities}
                            style={{width: '100%'}}
                            showSearch
                            filterOption={this.filterOption}
                            onSelect={this.selectCity.bind(this)}
                        >
                            {this.state.cities && this.state.cities.map(c => (
                                <Select.Option value={c.id} key={c.id} text={c.city_name}>
                                    {c.city_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={8}>
                        <Select
                            value={this.state.cityTo ? this.state.cityTo.id : null}
                            loading={!this.state.cities}
                            placeholder="Куда"
                            disabled={!this.state.cities}
                            style={{width: '100%'}}
                            showSearch
                            onSelect={cityId => this.selectCity(null, cityId)}
                            filterOption={this.filterOption}
                        >
                            {this.state.cities && this.state.cities.map(c => (
                                <Select.Option value={c.id}  key={c.id} text={c.city_name}>
                                    {c.city_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={8}>
                        <Select
                            value={this.state.partnerId}
                            placeholder="Партнёр"
                            disabled={!this.state.partners}
                            loading={!this.state.partners}
                            style={{width: '100%'}}
                            showSearch
                            onSelect={this.selectPartner.bind(this)}
                            filterOption={this.filterOption}
                        >
                            {this.state.partners && this.state.partners.map(p => (
                                <Select.Option key={p.id} text={p.partner_name}>
                                    {p.partner_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col span={24}>
                        <Select
                            style={{width: '100%'}}
                            defaultValue="ru"
                            value={this.state.country}
                            onSelect={country => this.setState({country})}
                        >
                            <Select.Option value="ru">Россия</Select.Option>
                            <Select.Option value="by">Беларусь</Select.Option>
                        </Select>
                    </Col>
                </Row>
                <div className={b('container')}>
                    <Header 
                        fromName={fromName} 
                        toName={toName}
                        country={this.state.country}
                    />
                    <table>
                        <tbody>
                            <tr className={`${b('weekdays')}`}>
                                {weekDays.map(w => <th
                                    className='nechet'
                                    key={w} colSpan={2}
                                >
                                    {w}
                                </th>)}
                            </tr>
                            <tr className={b('cities')}>
                                {weekDays.map(w => <React.Fragment
                                    key={w}
                                >
                                    <th className='gray'>{fromName}</th>
                                    <th className='nechet'>{toName}</th>
                                </React.Fragment>)}
                            </tr>
                            {_.range(0, maxLength).map(i => <tr
                                key={i}
                            >
                                {weekDays.map((w, weekNumber) => <React.Fragment key={w}>
                                    <td className='gray'>
                                        {_.get(this.state.schedule.from[weekNumber + 1], i)}
                                    </td>
                                    <td className='nechet'>
                                        {_.get(this.state.schedule.to[weekNumber + 1], i)}
                                    </td>
                                </React.Fragment>)}
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const Header = ({ fromName, toName, country }) => {
    const b = block('schedule-header');
    return <div className={b()}>
        <div className={b('left')}>
            <img className={b('logo')} src={logo} alt="logo" />
        </div>
        <div className={b('title')}>
            <h1>{fromName} — {toName}</h1>
            <h2>Расписание</h2>
        </div>
        <div className={b('right')}>
            <span className={b('link')}>atlasbus.{country}</span>
        </div>
    </div>
}

export default App;
