import axios from 'axios';


export default link => {
    return axios.get('/api/short', {
        params: {
            url: link
        }
    })
}
