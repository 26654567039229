import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import logo from './logo.svg';

import block from 'bem-cn';
import { Row, Col, Button } from 'antd';
import { Select, Form, Input, Modal } from 'antd';
import { transliterate } from 'transliteration';
import copy from 'copy-to-clipboard';
import { Helmet } from "react-helmet";

import ShortLink from '../../Utils/ShortLink';
import { URLS, SOURCES, ALLOWED_DOMAINS } from './constants';
import './urls.scss';

const { Option } = Select;

const QR = ({size, link}) => <div style={{maxWidth: '100%'}}><QRCode 
    size={1000}
    style={{
        width: size,
        height: 'auto',
        maxWidth: '100%'
    }}
    logoImage={logo}
    logoWidth={1000*0.325}
    fgColor='#30A1CE'
    bgColor='#00000000'
    ecLevel='H'
    value={link}
/></div>



export default class Urls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sources: SOURCES,
            values: {
                source: Object.keys(SOURCES)[0],
                linkType: 'web',
                country: 'ru',
                type: null,
                comment: '',
                shortLink: null,
                customLink: ''
            },
            showQR: false
        }
        this.state.values.type = Object.keys(this.types())[0];
    }
    types() {
        return this.state.sources[this.state.values.source].types;
    }
    valueChanged(key, e) {
        const values = this.state.values;
        values.shortLink = null;
        values[key] = e;
        this.setState({values}, () => {
            if (key === 'source') {
                values.type = Object.keys(this.types())[0]
                this.setState({values})
            }
        })
    }
    canonizedComment() {
        var result = transliterate(this.state.values.comment);
        result = result.toLowerCase();
        result = result.replace(/\s/g, '_');
        return result;
    }
    generateLink(isQr) {
        const {values} = this.state;
        if (values.shortLink) {
            return values.shortLink;
        }
        var baseLink = values.customLink ? 
            values.customLink :
            URLS[values.country][values.linkType];
        const params = new URLSearchParams();
        params.set('utm_source', values.source);
        params.set('utm_medium', values.type);
        params.set('utm_campaign', this.canonizedComment());
        if (isQr) {
            params.set('utm_content', 'qr')
        }
        if (values.linkType !== 'web') {
            params.set('utm_ref', encodeURIComponent(params.toString()));
        }
        return baseLink + '?' + params.toString();
    }
    isInvalid() {
        if (this.state.values.linkType === 'custom') {
            if (this.state.values.customLink.length < 1) {
                return 'Введите ссылку';
            }
            try {
                const url = new URL(this.state.values.customLink);
                if (ALLOWED_DOMAINS.indexOf(url.hostname) < 0) {
                    return 'Поддерживаются только ссылки на Атлас и приложения Атласа';
                }

            } catch (err) {
                return 'Некорректная ссылка';
            }
            
        }
        if (this.state.values.comment.length < 1) {
            return 'Необходимо указать название группы';
        };
        return false;
    }
    shortLink(isQr) {
        return new Promise(resolve => {
            if (!this.state.values.shortLink) {
                ShortLink(this.generateLink(isQr))
                .then(({data}) => {
                    const link = `https://${data.domain}/${data.slug}`
                    this.valueChanged('shortLink', link);
                    copy(link);
                    resolve();
                });
            } else {resolve()}
        })
    }
    qrCode() {
        this.shortLink(true)
            .then(() => {
                Modal.info({
                    content: <QR size={400} link={this.generateLink()} />,
                    width: 600
                })
            })
    }
    render() {
        const b = block('urls');
        const {values} = this.state;
        return <div className={b()}>
            <Helmet>
                <title>Атлас — Генератор ссылок</title>
            </Helmet>
            <Row>
                <Col span={24}>
                    <h1>Генератор ссылок</h1>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col md={12} >
                    <Form.Item label="Тип ссылки">
                        <Select 
                            onChange={e => this.valueChanged('linkType', e)}
                            value={values.linkType} 
                            size='large' style={{width: '100%'}}
                        >
                            <Option value="web">Сайт</Option>
                            <Option value="app">Приложение (Android + iOS)</Option>
                            <Option value="android">Приложение Android</Option>
                            <Option value="ios">Приложение iOS</Option>
                            <Option value="custom">Своя ссылка</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item label="Страна">
                        <Select 
                            disabled={values.linkType === 'custom'}
                            value={values.country} 
                            onChange={e => this.valueChanged('country', e)}
                            size='large' style={{width: '100%'}} 
                        >
                            <Option value="ru">Россия</Option>
                            <Option value="by">Беларусь</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {values.linkType === 'custom' && <Row><Col>
                <Form.Item label="Адрес ссылки">
                    <Input 
                        size='large'
                        value={values.customLink}
                        onChange={e => this.valueChanged('customLink', e.target.value)}
                    />
                </Form.Item>
            </Col></Row>}
            <Row gutter={20}>
                <Col md={12}>
                    <Form.Item label="Где будет размещаться">
                        <Select 
                            onChange={e => this.valueChanged('source', e)}
                            value={values.source}
                            size='large' style={{width: '100%'}} 
                        >
                            {Object.keys(this.state.sources).map(s => (
                                <Option key={s} value={s}>
                                    {this.state.sources[s].name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item label="Тип размещения">
                        <Select 
                            value={this.state.values.type}
                            onChange={e => this.valueChanged('type', e)}
                            size='large' style={{width: '100%'}}
                        >
                            {Object.keys(this.types()).map(s => (
                                <Option key={s} value={s}>
                                    {this.types()[s]}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row><Col>
                <Form.Item label="Название группы, кампании или таргета">
                    <Input
                        size='large'
                        value={values.comment}
                        onChange={e => this.valueChanged('comment', e.target.value)}
                    />
                </Form.Item>
            </Col></Row>
            <Row><Col>
                {this.isInvalid() ? 
                    <p style={{color: 'red'}}>
                        {this.isInvalid()}
                    </p> :
                    <p style={{wordBreak: 'break-all', padding: '10px 0'}}>
                        <a target="_blank" rel="noopener noreferrer" href={this.generateLink()}>{this.generateLink()}</a>
                    </p>
                }
            </Col></Row>
            <Row><Col>
                <div className={b('buttons')}>
                    <Button 
                        onClick={() => copy(this.generateLink())} 
                        disabled={this.isInvalid()} 
                        type='primary'>Скопировать</Button>
                    <Button 
                        onClick={() => this.shortLink()}
                        disabled={this.isInvalid()} 
                        type='default'>Короткая ссылка</Button>
                    <Button 
                        onClick={() => this.qrCode()}
                        disabled={this.isInvalid()} 
                        type='default'>QR код</Button>
                </div>
            </Col></Row>
        </div>
    }
}

