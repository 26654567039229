const ALLOWED_DOMAINS = [
    'atlasbus.ru',
    'atlasbus.by',
    'redirect.appmetrica.yandex.com',
    'play.google.com',
    'itunes.apple.com'
]

const URLS = {
    ru: {
        web: "https://atlasbus.ru",
        app: "https://redirect.appmetrica.yandex.com/serve/1035613447536050720",
        ios: "https://redirect.appmetrica.yandex.com/serve/1035613447536050720",
        android: "https://redirect.appmetrica.yandex.com/serve/1035613447536050720"
    },
    by: {
        web: "https://atlasbus.by",
        app: "https://redirect.appmetrica.yandex.com/serve/315055061778568791",
        ios: "https://redirect.appmetrica.yandex.com/serve/315055061778568791",
        android: "https://redirect.appmetrica.yandex.com/serve/315055061778568791"
    }
}

const SOURCES = {
    vk: {
        name: "ВКонтакте",
        types: {
            wall_post: "Пост в группе",
            public_ad: "Рекламный пост",
            target_ad: "Таргетированная реклама",
            other: "Другое"
        }
    },
    mailing: {
        name: 'Рекламная рассылка',
        types: {
            sms: 'СМС',
            whatsapp: 'Whats App',
            viber: 'Viber',
            email: 'Email'
        }
    },
    oom: {
        name: 'Наружная реклама',
        types: {
            banner: 'Баннер',
            incar_banner: 'Плакат в машине',
            sticket: 'Стикер в машине'
        }
    },
    other: {
        name: "Другое",
        types: {}
    }
}

export { URLS, SOURCES, ALLOWED_DOMAINS }