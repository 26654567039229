import React from 'react';
import { Button } from 'antd';


export default class Pics extends React.Component {
    save() {
        // http://stackoverflow.com/questions/3768565/drawing-a-svg-file-on-a-html5-canvas

        var svg = this.refs.svg;
        var img = this.refs.img;
        var canvas = this.refs.canvas;

        // get svg data
        var xml = new XMLSerializer().serializeToString(svg);

        // make it base64
        var svg64 = btoa(xml);
        var b64Start = 'data:image/svg+xml;base64,';

        // prepend a "header"
        var image64 = b64Start + svg64;

        // set it as the source of the img element
        img.src = image64;

        // draw the image onto the canvas
        img.onload = () => {
            canvas.getContext('2d').drawImage(img, 0, 0);
        }

        // var dt = canvas.toDataURL('image/png'); // << this fails in IE/Edge...
        // dt = dt.replace(/^data:image\/[^;]*/, 'data:application/octet-stream');
        // dt = dt.replace(/^data:application\/octet-stream/, 'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png');
        // this.href = dt;
    }
    render() {
        return <div>
            <svg ref="svg" id="test">
                <rect x="0" y="0" width="200" height="200" fill="yellowgreen"/>
                <foreignObject x="0" y="0" width="200" height="200">
                    <p contenteditable style={{fontFamily: 'Roboto'}} xmlns="http://www.w3.org/1999/xhtml">
                        Text goes here Text goes here Text goes here Text goes here Text goes here
                    </p>
                </foreignObject>
            </svg>
            <img ref='img' alt='test' />
            <canvas ref='canvas' />
            <Button onClick={this.save.bind(this)}>Save</Button>
        </div>
    }
}